<template>
  <span>
    {{ address }}
  </span>
</template>
<script setup lang="ts">
import type { CustomerAddress, ShippingCartAddress } from '@robustastudio/e-commerce/common';
import { isString } from 'lodash-es';

const props = defineProps({
  value: {
    type: Object as PropType<
      | CustomerAddress
      | ShippingCartAddress
      | NonNullable<ReturnType<typeof useOrder>['order']['value']>['shipping_address']
    >,
    default: () => ({}),
  },
});

const address = computed(
  () =>
    props.value &&
    mapAddressFormat({
      apartment: props.value.apartment || '',
      city:
        {
          name: props.value.city?.name || '',
        } || undefined,
      // filter to be exactly strings
      street: props.value?.street?.filter((item): item is string => isString(item)) || [],
      building: props.value.building || '',
      country: {
        name: props.value.country?.name || '',
      },
      floor: props.value.floor || '',
      region:
        {
          region: '',
          region_code: '',
          region_id: 0,
        } || '',
    }),
);
</script>
